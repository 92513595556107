export default {
  data () {
    return {
      jobs: [],
      jobInfoList: []
    }
  },
  methods: {
    /**
     * 根据订单id获取job列表
     * @param {String} orderId:订单id
     * @param {Number} size:数据条数
     */
    getJobsByOrderId (orderId, size = 10000) {
      return new Promise(resolve => {
        this.$request.get({
          url: this.$apis.createNewJob,
          params: {
            orderId,
            size
          }
        }).then(res => {
          if (res?.code === 1000) {
            this.jobs = res.data.content || []
            resolve()
          }
        })
      })
    },

    /**
     * 根据job id获取loading info
     * @param {String} jobId:一个job的id
     */
    getJobInfoByJobId (jobId) {
      const url = `${this.$apis.createNewJob}/${jobId}/info`
      this.$request.get({
        url
      }).then(res => {
        if (res?.code === 1000) {
          this.contractFormData.loadingInformation.push(res.data)
        }
      })
    }
  }
}
